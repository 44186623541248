.portfolio {
    padding: 4rem 2rem;
  }
  
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr; /* Only one column on smaller screens */
  }
}
  .portfolio-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .portfolio-item img {
    width: 100%;
    display: block;
  }
  
  .portfolio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .portfolio-item:hover .portfolio-overlay {
    opacity: 1;
  }
  
  .portfolio-overlay h3 {
    margin-bottom: 0.5rem;
  }
  
  .portfolio-overlay p {
    margin: 0;
  }
  