.about {
    padding: 4rem 2rem;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    gap: 20px;
  }

  .about-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  .about-text {
    flex: 1; 
    display: inline-block;
    min-width: 300px;
  }
  
  .about-text h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .about-text p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .skills-icons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 10px; 
  }
  
  .skill-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .skill-icon:hover {
    transform: scale(1.1);
  }
  