body {
  margin: 0;
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  background-color: whitesmoke;
}

.App {
  text-align: center;
}
